<template>
  <div class="d-flex flex-column flex-root">
    <transition name="fade-in-up">
      <router-view/>
    </transition>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/core/layout/extras/ScrollTop";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/store/core/htmlclass.module.js";

import {OVERRIDE_LAYOUT_CONFIG} from "@/store/core/config.module";

export default {
  name: "Layout",
  components: {
    KTScrollTop,
  },
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    setTimeout(() => {this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading")}, 2000);
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
